
/* 툴바 메뉴 크기 설정 */
.ql-snow .ql-picker.ql-lineHeight,
.ql-snow .ql-picker.ql-weight,
.ql-snow .ql-picker.ql-size {
  width: 50px !important;
}

.ql-snow .ql-picker.ql-letterSpacing,
.ql-snow .ql-picker.ql-textAlign {
  width: 70px !important;
}

/* Picker 라벨 설정 */
.ql-picker.ql-lineHeight .ql-picker-label::before,
.ql-picker.ql-lineHeight .ql-picker-item::before,
.ql-picker.ql-weight .ql-picker-label::before,
.ql-picker.ql-weight .ql-picker-item::before,
.ql-picker.ql-letterSpacing .ql-picker-label::before,
.ql-picker.ql-letterSpacing .ql-picker-item::before,
.ql-picker.ql-size .ql-picker-label::before,
.ql-picker.ql-size .ql-picker-item::before,
.ql-picker.ql-textAlign .ql-picker-label::before,
.ql-picker.ql-textAlign .ql-picker-item::before {
  content: attr(data-value) !important;
}
