@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@300;400;500;700&display=swap');

body * {
  white-space: pre-line;
  font-family: 'Noto Sans KR', sans-serif;
}

body li {
  list-style: none;
}

*::-webkit-scrollbar {
	display:none /* Chrome , Safari , Opera */
}

.banner-gradient {
  background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0) 40%,
      rgba(255, 255, 255, 0.3) 100%
  );
}

/* for Quill-Editor Styles */
@import './quill-editor.css';

/* for Swiper */
.swiper-wrapper {
  transition-timing-function: linear !important; /* Slides flow softly without stop */
}

.post-detail img {
  display: inline;
}