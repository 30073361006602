@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes slideIn {
  from { 
    opacity: 0;
    transform: scale(0.95) translateY(-10px);
  }
  to { 
    opacity: 1;
    transform: scale(1) translateY(0);
  }
}

.backdrop-animation {
  animation: fadeIn 0.3s ease-in-out;
}

.dialog-animation {
  animation: slideIn 0.3s ease-out;
}